/* General container styles */
.profile-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

/* Title styling */
.profile-title {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
    font-weight: 700;
}

/* Loading and error styles */
.loading,
.error {
    text-align: center;
    margin: 20px 0;
    font-size: 16px;
}

.loading {
    color: #007bff;
}

.error {
    color: #d9534f;
}

.token-section{
    width: 50%;
    margin: 2%;
}

.token-container{
    display: flex;
    flex-direction: row;
}

.user-details{
    display: flex;
    flex-direction: row;
}

.table-device{
    margin: 4%;
    width: 60%;
}

.logged-in-users{
    text-align: left;
}

/* Profile details and form */
.profile-details,
.profile-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: left;
    margin: 4%;
    width: 40%;
}

.detail-group {
    font-size: 18px;
    color: #555;
}

.detail-group strong {
    color: #333;
}

.form-checkbox {
    width: 20px;      /* Set the width of the checkbox */
    height: 20px;     /* Set the height of the checkbox */
    cursor: pointer;  /* Change the cursor to pointer when hovering */
    margin-left: 10px; /* Add some margin to separate from the label */
}

/* Optional: style for when the checkbox is checked */
.form-checkbox:checked {
    background-color: #007bff; /* Change the background color when checked */
    border-color: #007bff;     /* Change the border color when checked */
}

/* Optional: style the checkbox when it's disabled */
.form-checkbox:disabled {
    opacity: 0.5;  /* Make the checkbox semi-transparent */
    cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
}

/* Optional: focus state */
.form-checkbox:focus {
    outline: 2px solid #007bff; /* Add an outline when the checkbox is focused */
    outline-offset: 2px;
}

/* Avatar styling */
.avatar-group {
    display: flex;
    align-items: center;
    gap: 15px;
}

.avatar-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

/* Form group styling */
.form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.form-label {
    margin: 7px;
    font-weight: 600;
    color: #333;
}

.form-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    color: #333;
}

.form-input:focus {
    outline: none;
    border-color: #007bff;
}

.btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #fff;
    font-weight: 600;
    margin: 3%;
}

.btn-primary {
    background-color: #007bff;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-secondary {
    background-color: #6c757d;
}

.btn-secondary:hover {
    background-color: #5a6268;
}

.btn-edit {
    background-color: #28a745;
}

.btn-edit:hover {
    background-color: #218838;
}

.btn-file {
    background-color: #17a2b8;
}

.btn-file:hover {
    background-color: #138496;
}

.btn-generate {
    background-color: #ffc107;
    margin-top: 10px;
}

.btn-generate:hover {
    background-color: #e0a800;
}

.btn-copy {
    background-color: #007bff;
    margin-top: 10px;
}

.btn-copy:hover {
    background-color: #0056b3;
}

/* Token input and button groups */
.token-group {
    margin: 20px 0;
}

.token-group h3 {
    font-size: 22px;
    color: #333;
    margin-bottom: 10px;
}

.token-group input[type="text"],
.token-group input[type="password"] {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: calc(100% - 20px);
    box-sizing: border-box;
    font-size: 16px;
}

.token-group input[type="text"]:focus,
.token-group input[type="password"]:focus {
    border-color: #007bff;
}

/* Form actions */
.form-actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .profile-container {
        padding: 15px;
    }

    .profile-title {
        font-size: 24px;
    }

    .detail-group {
        font-size: 16px;
    }

    .form-input,
    .token-group input[type="text"],
    .token-group input[type="password"] {
        font-size: 14px;
    }

    .btn {
        padding: 8px 12px;
        font-size: 14px;
    }

    .token-group h3 {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .profile-title {
        font-size: 22px;
    }

    .avatar-image {
        width: 60px;
        height: 60px;
    }

    .btn {
        padding: 7px 10px;
        font-size: 12px;
    }

    .form-input,
    .token-group input[type="text"],
    .token-group input[type="password"] {
        font-size: 12px;
    }

    .token-group h3 {
        font-size: 18px;
    }
}
