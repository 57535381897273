/* src/CSVReader.css */
.csv-reader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    font-family: Arial, sans-serif;
  }
  
  .csv-reader h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .csv-reader input[type="file"] {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .csv-reader pre {
    background: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    overflow-x: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  