.input {
  padding: 5px 15px;
}

.load-modal {
  background-color: black;
}

.user-list {
  margin-top: 30px;
}

.btn_class {
  background-color: #fff;
  border: none;
}

.btn_class:hover {
  background-color: #fff;
  border: none;
}

.btn_class:active {
  background-color: #fff;
  border: none;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  background-color: #fff;
  border: none;
}

.user {
  list-style: none;
  margin: 10px 0;
  padding: 10px;
  background: #eee;
  display: flex;
  justify-content: space-between;
}

.user-id {
  color: red;
  margin-right: 20px;
}

.user-name {
  color: blue;
}

.user-age {
  margin-left: auto;
}

.circle_navbars {
  border-radius: 50%;
  margin: 10px;
  height: 7vh;
  width: 7vw;
  display: inline-block;
  position: relative;
  top: -20px;
}

.logo {
  max-width: 100px;
  max-height: 100px;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #ffff;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
  position: relative;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #ffff;
  color: #313b4c;
}

.sidebar-header h3 {
  color: #313b4c;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  color: #adb5bd;
  font-weight: 600;
  font-size: 15px;
  margin: 10px;
}

.nav-item.open {
  color: #e9ecef;
  background: #ffff;
}

.nav-item.active {
  color: #e9ecef;
  background: #fff !important;
  margin: 10px;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: .2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header>button {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

.content {
  padding: 0px;
  margin-left: 0;
  height: 100vh;
  position: relative;
}

.navbar {
  display: block;
  top: 0;
  z-index: 1;
  width: 97vw;
  margin: auto;
  max-height: 45vh;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  justify-content: space-around;
}

.single-page{
  margin: auto;
  padding: 2% 5%;
}

.content-bar {
  display: block;
  position: absolute;
  width: 90vw;
  overflow: hidden;
}

.box {
  width: 20px;
  height: 20px;
  color: blue;
  position: relative;
  display: flex;
  flex-direction: row;
  left: 7%;
}

.row-cont {
  display: block;
  height: 100vh;
  padding-top: 10%;
  position: relative;
  justify-content: space-around;
}

.origin {
  position: relative;
  top: 5px;
  left: -20px;
}

.destination {
  position: relative;
  margin-left: 10%;
  top: -23px;
}

.box_line {
  position: relative;
  top: 11%;
  margin: 10px;
  height: 8vh;
  border-left: 2px dashed #f37cb2;
}

.box_title {
  text-align: left;
  font-size: 15px;
  margin: 6% 0%;
}

.box1 {
  width: 20px;
  height: 20px;
  background-color: #adb5bd;
}

.row-cont1 {
  display: block;
  position: relative;
  justify-content: space-around;
  height: 70vh;
}

.row-cont1 span {
  position: relative;
  margin-left: 10%;
  top: -23px;
}

.box_line1 {
  position: absolute;
  top: 4%;
  margin: 0px 10px;
  height: 4vh;
  border-left: 2px dashed #555;
}

.track_table {
  height: 80vh;
  width: auto;
  position: relative;
  top: 25px;
}

.track_table_common {
  height: 80vh;
  width: auto;
  position: relative;
}

.box_container {
  height: 28vh;
}

.load_image {
  width: 30vw;
  height: 86vh;
}

.number_span {
  display: inline-block;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 4%;
  background-color: darkgrey;
}

.custom-marker {
  width: 25px;
  height: 25px;
}

.line_ck {
  border-bottom: 3px solid #adb5bd;
  position: relative;
  display: inline-block;
  top: -10px;
}

.my-swal {
  z-index: 2000;
}

.header_col {
  margin-bottom: 40px;
  width: 70%;
  font-size: 18px;
  text-align: center;
}

.body_title {
  font-weight: 600;
  padding: 5px;
  font-size: 15px;
  border-bottom: 2px solid #adb5bd;
}

.button_add {
  width: 20vw;
  height: 7vh;
  margin: 1%;
  background-color: #313b4c;
}

.button_add1 {
  width: 13vw;
  height: 5vh;
  margin: 1%;
  background-color: #313b4c;
}

.row_track {
  display: 'block';
  position: relative;
  margin: 0% 0% 3% 0%;
}

.box_small {
  border: 5px solid hsl(203, 96%, 72%);
  width: 180px;
  height: 140px;
  margin: 5%;
  font-size: 10px;
  padding: 10%;
}

.box_small1 {
  border: 2px solid #555555;
  width: 180px;
  height: 140px;
  margin: 5% 15%;
  font-size: 10px;
  padding: 10%;
}

.add_title {
  text-align: center;
  position: relative;
}

/* .modal-fullscreen .modal-content {
  height: 252% !important;
  border: 0;
  border-radius: 0;
} */
.box_container1 {
  height: 25%;
}

.box-card1 {
  position: relative;
  height: 100vh;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  border: none;
}

.box-card {
  border: none;
}

.c-card {
  max-height: 50vh;
}

#demo-select-small{
  background-color: #fff !important;
}

@media only screen and (max-width: 768px){
  .mobile-screen-single-page-side-row{
    display: none;
  }
  
  .mobile-screen-single-page-row{
      flex: 0 0 auto;
      width: 100%;
      height: 100vh;
      margin-top: 15%;
  }

  .row-cont {
    display: block;
    height: 65vh;
    padding-top: 10%;
    position: relative;
    justify-content: space-around;
}

}

@media only screen and (max-width: 555px) {
  .track_table {
    top: -50px
  }
}

@media only screen and (max-width: 575.98px) {

  /* .modal-fullscreen .modal-content {
    height: 442% !important;
  } */
   .single-page{
    height: 220vh;
   }
  .box-card {
    width: 70vw;
  }

  .body_container {
    font-size: 6px;
  }

  .button_add {
    display: none;
  }

  .button_add1 {
    width: 60vw;
    height: 6vh;
    margin: 4%;
  }

  .row_track {
    margin: 0% 0% 7% 0%;
  }

  .box_small {
    width: 100px;
    height: 100px;
    font-size: 7px;
  }

  .box_small1 {
    width: 100px;
    height: 100px;
    font-size: 8px;
  }

  .line_ck {
    top: -40px;
    left: 6px;
  }

  /* body {
    overflow:  hidden;
  } */

  .content-bar {
    width: 70vw;
  }

  .content.is-open {
    display: block;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
  }

  .sidebar.is-open>.sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }

  .navbar {
    top: 38px;
  }

  .track_table {
    top: 5px;
}
}

@media screen and (max-width:375px) {
  .box_small {
    width: 80px;
    height: 80px;
    font-size: 3px;
  }

  .box_small1 {
    width: 80px;
    height: 80px;
    font-size: 3px;

  }
}

@media only screen and (max-width:280px) {
  .box_line {
    top: 2%;
    height: 3vh;
  }

  .box_small1 {
    width: 67px;
    height: 67px;
    font-size: 3px;
  }

  .box_small {
    width: 67px;
    height: 67px;
    font-size: 3px;
  }
}

@media (min-width:281px) and (max-width:320px) {
  .box_line {
    top: 2%;
    height: 5vh;
    margin: 10px 0px;
  }
}

@media (min-width:321px) and (max-width: 359px) {
  .box_line {
    top: 8%;
    height: 5vh;
    margin: 10px 2px;
  }
}

@media (min-width:360px) and (max-width:373px) {
  .box_line {
    position: relative;
    top: 11%;
    margin: 5px;
    height: 4vh;
    border-left: 2px dashed #f37cb2;
  }
}

@media (min-width: 374px) and (max-width: 389px) {
  .box_line {
    top: 6%;
    height: 5vh;
    margin: 10px 4px;
  }
}

@media (min-width: 390px) and (max-width: 475px) {
  .box_line {
    top: 8%;
    height: 5vh;
    margin: 10px 4px;
  }
}

@media (min-width: 476px) and (max-width: 539px) {
  .box_line {
    top: 7%;
    height: 4vh;
  }
}

@media (min-width:761px) and (max-width:767px) {
  .box_line {
    position: relative;
    top: 11%;
    margin: 10px 20px;
    height: 3vh;
    border-left: 2px dashed #f37cb2;
  }
}

@media (min-width:540px) and (max-width:760px) {
  .box_line {
    top: 4%;
    margin: 15px 15px;
    height: 4vh;
  }

  .box_small {
    width: 120px;
    height: 120px;
    font-size: 8px;
  }

  .box_small1 {
    width: 120px;
    height: 120px;
    font-size: 8px;
  }

  .content.is-open {
    display: none;
    transition: all 0.5s;
  }

  .content-bar {
    width: 70vw;
  }

  .row_track {
    margin: 0% 0% 7% 0%;
  }

  .track_table {
    top: 25px;
}
}

@media (min-width:820px) and (max-width:853px) {
  .button_add {
    display: none;
  }

  .button_add1 {
    width: 16vw;
    height: 7vh;
    margin: 4%;
  }

  .box_line1 {
    top: 3%;
    height: 2vh;
  }
}

@media (min-width:854px) and (max-width:880px) {
  .button_add1 {
    width: 16vw;
    height: 4vh;
    margin: 4%;
  }
}

@media (min-width:768px) and (max-width:819px) {
  .box_line {
    top: 3%;
    height: 4vh;
  }

  .box_container {
    height: 20vh;
  }
}

@media (min-width:820px) and (max-width:911px) {
  .box_line {
    top: 3%;
    height: 3vh;
  }

  .box_container {
    height: 20vh;
  }
}

@media (min-width:912px) and (max-width:980px) {
  .box_line {
    top: 3%;
    height: 4vh;
  }
}

@media (min-width:981px) and (max-width:1023px) {
  .box_line {
    top: 6%;
    height: 11vh;
  }
}

@media (min-width:1024px) and (max-width:1054px) {
  .box_line {
    top: 3%;
    height: 3vh;
  }

  .box_container {
    height: 27vh;
  }

  .button_add1 {
    width: 1vw;
    height: 5vh;
    margin: 4%;
  }

  .button_add {
    width: 15vw;
    height: 4vh;
    margin: 1%;
  }
}

@media (min-width:1060px) and (max-width:1280px) {
  .box_line {
    top: 2%;
    height: 3vh;
  }

  .button_add {
    width: 13vw;
    height: 3vh;
    margin: 1%;
    background-color: #313b4c;
  }
}

@media (min-width:767px) and (max-width:820px) {
  .box_small {
    width: 100px;
    height: 100px;
    font-size: 6px;
  }

  .box_small1 {
    width: 100px;
    height: 100px;
    font-size: 6px;
    margin: 3% 3% 3% 55%;
  }

  .line_ck {
    top: -35px;
  }

  .button_add {
    display: none;
  }

  .button_add1 {
    width: 30vw;
    height: 7vh;
    margin: 4%;
  }

  .box_line {
    top: 3%;
    height: 4vh;
    margin: 10px -3px;
  }

  .track_table {
    top: 15px;
}
}

@media (min-width:821px) and (max-width:1023px) {
  .box_small {
    width: 130px;
    height: 130px;
  }

  .box_small1 {
    width: 130px;
    height: 130px;
    margin-left: 50%;
  }

  .line_ck {
    top: -35px;
  }

  .button_add {
    width: 17vw;
    height: 6vh;
  }

  .box_line {
    top: 15%;
    height: 3vh;
    margin: 1px;
  }

  .track_table {
    top: 18px;
}
}

@media (min-width:1000px) and (max-width:1300px) {
  .line_ck {
    top: -40px;
  }

  .button_add1 {
    width: 25vw;
    height: 6vh;
    margin: 3%;
  }

  .track_table {
    top: 10px;
}
}

@media (min-width:1138px) and (max-width:1300px) {
  .box_line {
    position: relative;
    top: 12%;
    margin: 8px;
    height: 4vh;
    border-left: 2px dashed #f37cb2;
  }
}

@media (min-width:1000px) and (max-width:1137px) {
  .box_line {
    top: 6%;
    height: 5vh;
  }
}

@media (min-width:1301px) and (max-width:1800px) {
  .button_add {
    height: 5vh;
    width: 12vw;
  }

  .button_add1 {
    width: 20vw;
    height: 5vh;
  }

  .box_line {
    top: 7%;
    margin: 10px;
    height: 4vh;
  }
}

@media (min-width:1530px) and (max-width:1800px) {
  .box_line {
    top: 12%;
    margin: 10px;
    height: 3vh;
  }
}

@media (min-width:1801px) and (max-width:1980px) {
  .box_line {
    position: absolute;
    top: 11%;
    margin: 11px 19px;
    height: 4vh;
    border-left: 2px dashed #555;
  }
}

@media (min-width:1981px) and (max-width:2116px) {
  .box_line {
    position: absolute;
    top: 12%;
    margin: 10px 20px;
    height: 4vh;
    border-left: 2px dashed #555;
  }

  .track_table {
    top: -111px;
  }

}

@media (min-width:2116px) and (max-width:2531px) {
  .box_line {
    position: relative;
    top: 1%;
    margin: 5px 25px;
    height: 5vh;
    border-left: 2px dashed #f37cb2;
  }

}

@media (min-width:2532px) and (max-width:2560px) {
  .box_line {
    position: absolute;
    top: 15%;
    margin: 9px 27px;
    height: 3vh;
    border-left: 2px dashed #555;
  }
}

@media (min-width:1801px) and (max-width:2560px) {
  .button_add {
    width: 9vw;
    max-height: 3vh;
  }

  .button_add1 {
    width: 11vw;
    height: 3vh;
    font-size: 17px;
}
  .box_container {
    height: 21vh;
    font-size: 25px !important;
  }

  .box_line1 {
    top: 1%;
    height: 2vh;
  }

  .box {
    width: 50px;
    height: 77px;
  }

  .track_table {
    top: 30px;
}
}

@media screen and (min-width:2561px) {
  .button_add {
    height: 3vh;
    margin: 1%;
    width: 7vw;
  }

  .track_table {
    top: 30px;
}
}