/* old designer css start */
.App {
  text-align: center;
  display: block;
}

.flex-justify-end-z-400 {
  display: flex;
  justify-content: flex-start;
  position: relative;
  z-index: 999;
  padding-top: 8%;
  padding-left: 1%;
  font-size: 8px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

path.leaflet-interactive.animate {
  color: #2d2d86 !important;
  stroke-width: 4px;
  stroke-dasharray: 1920;
  stroke-dashoffset: 1920;
  animation: dash 15s linear 1s forwards;
}

.swal2-container {
  z-index: X;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.leaflet-control-attribution {
  display: none;
}

.leaflet-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

#map-wrapper {
  position: relative;
  width: 100%;
  height: 80vh;
  margin: 0 auto;
}

#map-wrapper-Truck {
  position: relative;
  width: 100%;
  height: 65vh;
  margin: 0 auto;
}

.custom-cluster-icon {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  text-align: center;
  line-height: 40px;
}

.custom-cluster-icon-inner {
  font-size: 14px;
  font-weight: bold;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* old designer css end */


/* header css start */
p.logo-text{
  font-size: 30px;
  font-weight: 500;
  color: #fff;
}
/* header css end */
/* icon top */
.icon-top{
  cursor: pointer;
  position: fixed;
  z-index: 999;
  bottom: 30px;
  right: 20px;
  display: block;
}
.icon-top svg{
  width: 60px;
  height: 60px;
  transition: var(--transition-smooth);
}
.icon-top:hover{
  color: rgba(var(--clr-white), 0.7);
}
/* footer css start */
footer{
  backdrop-filter: blur(10px);
  border-top: 1px solid #f1f1f1;
  min-height: var(--header-height);
}
/* footer css end */
/* home page css start */
.hero-section,.about-section,.contact-form-section{
  min-height: calc(100vh - var(--header-height));
}
.contact-form-section form{
  /* max-width: 700px; */
  margin: 50px auto;
  backdrop-filter: blur(10px);
  padding: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px 0;
}
.form-control-label .MuiFormControlLabel-label{
  font-size: 18px;
  line-height: 130%;
  color: rgba(var(--clr-white), 1);
  font-weight: normal;
}
.form-control-label .MuiSvgIcon-root{
  width: 2em;
  height: 2em;
  fill: var(--clr-black);
}
/* home page css end */
/* default-content-box */
.default-content-box{
  backdrop-filter: blur(10px);
  padding: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
}
/* web-form-wrap css */
.web-form-wrap{
  padding: 40px;
  box-shadow: inset rgba(0, 0, 0, 0.35) 0px -3px 20px 9px;
}
/* responsive css start */
@media (max-width: 767px) {
  /* old designer css */
  #map-wrapper, #map-wrapper-Truck {
    width: 100%;
    height: 50vh;
  }

  .leaflet-pane {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 400;
  }
  
  .leaflet-tile-pane {
    z-index: 200;
  }
  
  .leaflet-overlay-pane {
    z-index: 300;
  }
  /* old designer end */
}
@media screen and (max-width:575px) {
  /* old designer css */
  .leaflet-container {
    overflow: visible !important;
    position: relative !important;
    height: 60vh;
  }
  #map-wrapper, #map-wrapper-Truck {
    height: 60vh; 
    margin: 13% auto;
    overflow: hidden;
  }
  /* old designer end */
}
/* responsive css end */

